<template>
  <div id="table-calendar">
    <table style="width: 100%">
      <thead>
        <tr>
          <th width="180">Auditor (ผู้ตรวจ)</th>
          <th
            v-for="(day, index) in dayOfWeek"
            :key="index"
            v-html="weekScale(day)"
          ></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, index) in fetchDay" :key="index">
          <td>
            <div class="is-flex ai-center">
              <el-avatar
                icon="el-icon-user-solid"
                class="mg-r-3"
                :src="
                  row.profileImage != ''
                    ? imageUrl + 'profile/' + row._id + '/' + row.profileImage
                    : ''
                "
              ></el-avatar>
              <span>
                {{ row.name }}
              </span>
            </div>
          </td>
          <td>
            <div class="custom-tag" v-if="row.day.day1.length > 0">
              <div
                v-for="(calendar, index) in row.day.day1"
                :key="index"
                :style="`background:${calendar.tagColor}`"
                class="card-tags"
              >
                <el-tooltip
                  :popper-class="`popper-calendar`"
                  placement="right-start"
                >
                  <div slot="content">
                    <span class="font-12">
                      {{ calendar.calendarName }}
                    </span>
                    <span class="font-12" v-if="calendar.status == 2">
                      <br />
                      {{
                        calendar.companyType.type == 1
                          ? mapDataOption(
                              calendar.companyType.standard,
                              optionsStandardFood,
                              "standard"
                            )
                          : mapDataOption(
                              calendar.companyType.standard,
                              calendar.companyType.company == 1
                                ? optionsStandardIsoOne
                                : optionsStandardIsoTwo,
                              "standard"
                            )
                      }}
                      {{
                        calendar.companyType.standard == 99
                          ? `(${calendar.companyType.other})`
                          : ""
                      }}{{ ` / ` }}

                      {{
                        calendar.statusChecking == null ||
                        calendar.statusChecking == ""
                          ? "-"
                          : mapDataOption(
                              calendar.statusChecking,
                              optionCalendar.statusChecking
                            )
                      }}
                    </span>
                  </div>
                  <InsideTags
                    @editCalendar="
                      (id, type) => $emit('editCalendar', id, type)
                    "
                    @openCalendar="
                      (id, type) => $emit('openCalendar', id, type)
                    "
                    :calendar="calendar"
                  />
                </el-tooltip>
              </div>
            </div>
          </td>
          <td>
            <div class="custom-tag" v-if="row.day.day2.length > 0">
              <div
                v-for="(calendar, index) in row.day.day2"
                :key="index"
                :style="`background:${calendar.tagColor}`"
                class="card-tags"
              >
                <el-tooltip
                  :popper-class="`popper-calendar`"
                  placement="right-start"
                >
                  <div slot="content">
                    <span class="font-12">
                      {{ calendar.calendarName }}
                    </span>
                    <span class="font-12" v-if="calendar.status == 2">
                      <br />
                      {{
                        calendar.companyType.type == 1
                          ? mapDataOption(
                              calendar.companyType.standard,
                              optionsStandardFood,
                              "standard"
                            )
                          : mapDataOption(
                              calendar.companyType.standard,
                              calendar.companyType.company == 1
                                ? optionsStandardIsoOne
                                : optionsStandardIsoTwo,
                              "standard"
                            )
                      }}
                      {{
                        calendar.companyType.standard == 99
                          ? `(${calendar.companyType.other})`
                          : ""
                      }}{{ ` / ` }}

                      {{
                        calendar.statusChecking == null ||
                        calendar.statusChecking == ""
                          ? "-"
                          : mapDataOption(
                              calendar.statusChecking,
                              optionCalendar.statusChecking
                            )
                      }}
                    </span>
                  </div>
                  <InsideTags
                    @editCalendar="
                      (id, type) => $emit('editCalendar', id, type)
                    "
                    @openCalendar="
                      (id, type) => $emit('openCalendar', id, type)
                    "
                    :calendar="calendar"
                  />
                </el-tooltip>
              </div>
            </div>
          </td>
          <td>
            <div class="custom-tag" v-if="row.day.day3.length > 0">
              <div
                v-for="(calendar, index) in row.day.day3"
                :key="index"
                :style="`background:${calendar.tagColor}`"
                class="card-tags"
              >
                <el-tooltip
                  :popper-class="`popper-calendar`"
                  placement="right-start"
                >
                  <div slot="content">
                    <span class="font-12">
                      {{ calendar.calendarName }}
                    </span>
                    <span class="font-12" v-if="calendar.status == 2">
                      <br />
                      {{
                        calendar.companyType.type == 1
                          ? mapDataOption(
                              calendar.companyType.standard,
                              optionsStandardFood,
                              "standard"
                            )
                          : mapDataOption(
                              calendar.companyType.standard,
                              calendar.companyType.company == 1
                                ? optionsStandardIsoOne
                                : optionsStandardIsoTwo,
                              "standard"
                            )
                      }}
                      {{
                        calendar.companyType.standard == 99
                          ? `(${calendar.companyType.other})`
                          : ""
                      }}{{ ` / ` }}

                      {{
                        calendar.statusChecking == null ||
                        calendar.statusChecking == ""
                          ? "-"
                          : mapDataOption(
                              calendar.statusChecking,
                              optionCalendar.statusChecking
                            )
                      }}
                    </span>
                  </div>
                  <InsideTags
                    @editCalendar="
                      (id, type) => $emit('editCalendar', id, type)
                    "
                    @openCalendar="
                      (id, type) => $emit('openCalendar', id, type)
                    "
                    :calendar="calendar"
                  />
                </el-tooltip>
              </div>
            </div>
          </td>
          <td>
            <div class="custom-tag" v-if="row.day.day4.length > 0">
              <div
                v-for="(calendar, index) in row.day.day4"
                :key="index"
                :style="`background:${calendar.tagColor}`"
                class="card-tags"
              >
                <el-tooltip
                  :popper-class="`popper-calendar`"
                  placement="right-start"
                >
                  <div slot="content">
                    <span class="font-12">
                      {{ calendar.calendarName }}
                    </span>
                    <span class="font-12" v-if="calendar.status == 2">
                      <br />
                      {{
                        calendar.companyType.type == 1
                          ? mapDataOption(
                              calendar.companyType.standard,
                              optionsStandardFood,
                              "standard"
                            )
                          : mapDataOption(
                              calendar.companyType.standard,
                              calendar.companyType.company == 1
                                ? optionsStandardIsoOne
                                : optionsStandardIsoTwo,
                              "standard"
                            )
                      }}
                      {{
                        calendar.companyType.standard == 99
                          ? `(${calendar.companyType.other})`
                          : ""
                      }}{{ ` / ` }}

                      {{
                        calendar.statusChecking == null ||
                        calendar.statusChecking == ""
                          ? "-"
                          : mapDataOption(
                              calendar.statusChecking,
                              optionCalendar.statusChecking
                            )
                      }}
                    </span>
                  </div>
                  <InsideTags
                    @editCalendar="
                      (id, type) => $emit('editCalendar', id, type)
                    "
                    @openCalendar="
                      (id, type) => $emit('openCalendar', id, type)
                    "
                    :calendar="calendar"
                  />
                </el-tooltip>
              </div>
            </div>
          </td>
          <td>
            <div class="custom-tag" v-if="row.day.day5.length > 0">
              <div
                v-for="(calendar, index) in row.day.day5"
                :key="index"
                :style="`background:${calendar.tagColor}`"
                class="card-tags"
              >
                <el-tooltip
                  :popper-class="`popper-calendar`"
                  placement="right-start"
                >
                  <div slot="content">
                    <span class="font-12">
                      {{ calendar.calendarName }}
                    </span>
                    <span class="font-12" v-if="calendar.status == 2">
                      <br />
                      {{
                        calendar.companyType.type == 1
                          ? mapDataOption(
                              calendar.companyType.standard,
                              optionsStandardFood,
                              "standard"
                            )
                          : mapDataOption(
                              calendar.companyType.standard,
                              calendar.companyType.company == 1
                                ? optionsStandardIsoOne
                                : optionsStandardIsoTwo,
                              "standard"
                            )
                      }}
                      {{
                        calendar.companyType.standard == 99
                          ? `(${calendar.companyType.other})`
                          : ""
                      }}{{ ` / ` }}

                      {{
                        calendar.statusChecking == null ||
                        calendar.statusChecking == ""
                          ? "-"
                          : mapDataOption(
                              calendar.statusChecking,
                              optionCalendar.statusChecking
                            )
                      }}
                    </span>
                  </div>
                  <InsideTags
                    @editCalendar="
                      (id, type) => $emit('editCalendar', id, type)
                    "
                    @openCalendar="
                      (id, type) => $emit('openCalendar', id, type)
                    "
                    :calendar="calendar"
                  />
                </el-tooltip>
              </div>
            </div>
          </td>
          <td>
            <div class="custom-tag" v-if="row.day.day6.length > 0">
              <div
                v-for="(calendar, index) in row.day.day6"
                :key="index"
                :style="`background:${calendar.tagColor}`"
                class="card-tags"
              >
                <el-tooltip
                  :popper-class="`popper-calendar`"
                  placement="right-start"
                >
                  <div slot="content">
                    <span class="font-12">
                      {{ calendar.calendarName }}
                    </span>
                    <span class="font-12" v-if="calendar.status == 2">
                      <br />
                      {{
                        calendar.companyType.type == 1
                          ? mapDataOption(
                              calendar.companyType.standard,
                              optionsStandardFood,
                              "standard"
                            )
                          : mapDataOption(
                              calendar.companyType.standard,
                              calendar.companyType.company == 1
                                ? optionsStandardIsoOne
                                : optionsStandardIsoTwo,
                              "standard"
                            )
                      }}
                      {{
                        calendar.companyType.standard == 99
                          ? `(${calendar.companyType.other})`
                          : ""
                      }}{{ ` / ` }}

                      {{
                        calendar.statusChecking == null ||
                        calendar.statusChecking == ""
                          ? "-"
                          : mapDataOption(
                              calendar.statusChecking,
                              optionCalendar.statusChecking
                            )
                      }}
                    </span>
                  </div>
                  <InsideTags
                    @editCalendar="
                      (id, type) => $emit('editCalendar', id, type)
                    "
                    @openCalendar="
                      (id, type) => $emit('openCalendar', id, type)
                    "
                    :calendar="calendar"
                  />
                </el-tooltip>
              </div>
            </div>
          </td>
          <td>
            <div class="custom-tag" v-if="row.day.day7.length > 0">
              <div
                v-for="(calendar, index) in row.day.day7"
                :key="index"
                :style="`background:${calendar.tagColor}`"
                class="card-tags"
              >
                <el-tooltip
                  :popper-class="`popper-calendar`"
                  placement="right-start"
                >
                  <div slot="content">
                    <span class="font-12">
                      {{ calendar.calendarName }}
                    </span>
                    <span class="font-12" v-if="calendar.status == 2">
                      <br />
                      {{
                        calendar.companyType.type == 1
                          ? mapDataOption(
                              calendar.companyType.standard,
                              optionsStandardFood,
                              "standard"
                            )
                          : mapDataOption(
                              calendar.companyType.standard,
                              calendar.companyType.company == 1
                                ? optionsStandardIsoOne
                                : optionsStandardIsoTwo,
                              "standard"
                            )
                      }}
                      {{
                        calendar.companyType.standard == 99
                          ? `(${calendar.companyType.other})`
                          : ""
                      }}{{ ` / ` }}

                      {{
                        calendar.statusChecking == null ||
                        calendar.statusChecking == ""
                          ? "-"
                          : mapDataOption(
                              calendar.statusChecking,
                              optionCalendar.statusChecking
                            )
                      }}
                    </span>
                  </div>
                  <InsideTags
                    @editCalendar="
                      (id, type) => $emit('editCalendar', id, type)
                    "
                    @openCalendar="
                      (id, type) => $emit('openCalendar', id, type)
                    "
                    :calendar="calendar"
                  />
                </el-tooltip>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import moment from "moment";
import InsideTags from "@/components/calendar/InsideTags";
import { mapState } from "vuex";

export default {
  components: {
    InsideTags,
  },
  props: {
    dayOfWeek: { type: Array, default: () => [] },
    fetchDay: { type: Array, default: () => [] },
  },
  data() {
    return {
      imageUrl: process.env.VUE_APP_BASE_URL_IMAGE,
    };
  },
  computed: {
    ...mapState({
      optionsStandardFood: (state) => state.optionAll.optionsStandardFood,
      optionsStandardIsoOne: (state) => state.optionAll.optionsStandardIsoOne,
      optionsStandardIsoTwo: (state) => state.optionAll.optionsStandardIsoTwo,
      optionCalendar: (state) => state.calendarStatus,
    }),
  },
  methods: {
    weekScale(date) {
      let dateNow = moment(new Date()).format("YYYY-MM-DD");
      let dateCalendar = moment(date).format("YYYY-MM-DD");
      let classActive = "set-date";
      if (dateNow == dateCalendar) {
        classActive += " active";
      }
      let dateHtml = `<div class='${classActive}'><span> ${moment(date).format(
        "ddd"
      )}  </span>  <br/> <span class='date-number'> ${moment(date).format(
        "DD"
      )}  </span> </div> `;
      return dateHtml;
    },
  },
};
</script>
