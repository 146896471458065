<template>
  <DefaultTemplate
    :footer="true"
    v-loading.lock="loading"
    element-loading-text="กำลังโหลดข้อมูล..."
    element-loading-spinner="el-icon-loading"
    id="calendar-page"
  >
    <div>
      <h2 class="mg-y-2">Calendar</h2>
      <el-row
        :gutter="15"
        class="mg-y-5 flex-wrap"
        type="flex"
        align="middle"
        justify="space-between"
      >
        <el-col :span="24" :sm="12" :md="6" :lg="6">
          <el-form ref="formFilter" :model="filter" label-position="top">
            <el-form-item label="Search">
              <el-input
                clearable
                placeholder="Search"
                v-model="filter.search"
                class="search"
                @input="searchAll('input')"
              >
                <i slot="prefix" class="fas fa-search font-14 mg-l-1"></i>
              </el-input>
            </el-form-item>
          </el-form>
        </el-col>

        <el-col :span="24" :md="12" :xl="16" class="text-right">
          <el-button
            type="warning"
            class="button-radius mg-t-2 font-14"
            @click="openAddCalendar()"
            v-if="user.role != 50"
          >
            <span class="font-14"> + Add Calendar </span>
          </el-button>
        </el-col>
      </el-row>
      <p class="text-center font-22">
        {{ dayOfWeek[0] | formatDateEN }}
        -
        {{ dayOfWeek[6] | formatDateEN }}
      </p>

      <div
        v-loading.lock="loadingTable"
        element-loading-text="กำลังโหลดข้อมูล..."
        element-loading-spinner="el-icon-loading"
        height="400"
      >
        <div class="box-slect-day">
          <el-button circle class="chevron" @click="setWeek('prev')">
            <i class="fas fa-chevron-left"></i>
          </el-button>

          <el-button class="day" @click="setWeek('today')"> TODAY </el-button>

          <el-button circle class="chevron" @click="setWeek('next')"
            ><i class="fas fa-chevron-right"></i>
          </el-button>
        </div>
        <CalendarCustom
          @editCalendar="getEditCalendar"
          @openCalendar="openCalendar"
          :dayOfWeek="dayOfWeek"
          :fetchDay="dataCalendar"
        />
      </div>
    </div>

    <el-dialog :visible.sync="dialogCalendar" :before-close="closeForm">
      <template slot="title">
        <p class="font-28 mg-b-less color-main-1" v-if="isModal == 'edit'">
          Edit Calendar
        </p>
        <p class="font-28 mg-b-less color-main-1" v-else-if="isModal == 'add'">
          Add Calendar
        </p>
        <p class="font-28 mg-b-less color-main-1" v-else-if="isModal == 'view'">
          Calendar
        </p>
      </template>
      <el-form
        :model="formCalendar"
        :rules="rules"
        ref="ruleForm"
        @submit.native.prevent="submitCalendar"
      >
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item prop="calendarName" label="Title">
              <el-input
                placeholder="Title name"
                v-model="formCalendar.calendarName"
                @blur="
                  formCalendar.calendarName = formCalendar.calendarName.trim()
                "
                :disabled="
                  isModal == 'view' || formCalendar.status == 2 ? true : false
                "
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24" :md="12">
            <el-form-item prop="date" label="Date (วันที่)">
              <el-date-picker
                type="date"
                placeholder="Pick a date"
                v-model="formCalendar.date"
                :picker-options="pickerOptions"
                :disabled="
                  isModal == 'view' || formCalendar.status == 2 ? true : false
                "
                style="width: 100%"
              ></el-date-picker>
            </el-form-item>
          </el-col>

          <el-col :span="24" :md="12">
            <el-form-item prop="timeStart" label="Time (เวลา)" class="time-box">
              <el-row :gutter="10">
                <el-col :span="24" :sm="12">
                  <el-time-select
                    placeholder="Start time"
                    @change="changeTime()"
                    v-model="formCalendar.timeStart"
                    :disabled="
                      isModal == 'view' || formCalendar.status == 2
                        ? true
                        : false
                    "
                    :picker-options="{
                      start: '07:00',
                      step: '00:30',
                      end: '19:00',
                    }"
                  >
                  </el-time-select>
                </el-col>
                <el-col :span="24" :sm="12">
                  <el-form-item prop="timeEnd" class="mg-b-less">
                    <el-time-select
                      placeholder="End time"
                      v-model="formCalendar.timeEnd"
                      :disabled="
                        isModal == 'view' || formCalendar.status == 2
                          ? true
                          : false
                      "
                      :picker-options="{
                        start: '07:00',
                        step: '00:30',
                        end: '19:00',
                        minTime: formCalendar.timeStart,
                      }"
                    >
                    </el-time-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="Auditor name (ชื่อผู้ตรวจ)" prop="auditorId">
              <el-select
                filterable
                clearable
                v-model="formCalendar.auditorId"
                placeholder="Select Audit cycle"
                :disabled="
                  user.role == 50 ||
                  isModal == 'view' ||
                  formCalendar.status == 2
                    ? true
                    : false
                "
              >
                <el-option
                  v-for="(item, index) in optionsAuditor"
                  :key="index"
                  :label="item.name"
                  :value="item._id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24" class="set-tag">
            <el-form-item label="Tag (แท็ก)" prop="tag">
              <div class="mg-t-1">
                <el-popover
                  placement="top-start"
                  width="350"
                  title="แท็ก"
                  popper-class="custom-tag"
                  trigger="click"
                >
                  <div>
                    <div v-show="tagIndex == 1">
                      <div class="max-box-calendar">
                        <div
                          v-for="(tagItem, index) in allTag"
                          :key="index"
                          class="mg-b-4 pd-x-4"
                          :class="
                            formCalendar.tag.length > 0 &&
                            formCalendar.tag[0]._id != tagItem._id
                              ? 'disabled-tag'
                              : 'cr-pointer'
                          "
                        >
                          <el-row :gutter="20" type="flex">
                            <el-col
                              :span="19"
                              :style="{ backgroundColor: tagItem.color }"
                              class="pd-5 round-left"
                            >
                              <div
                                class="grid-content pd-l-5 text-white"
                                @click="toggleTag(tagItem)"
                              >
                                {{ tagItem.name }}
                              </div>
                            </el-col>
                            <el-col
                              :span="2"
                              :style="{ backgroundColor: tagItem.color }"
                              class="pd-5 round-right"
                            >
                              <div
                                @click="toggleTag(tagItem)"
                                class="text-white"
                              >
                                <i
                                  class="el-icon-check"
                                  :style="{
                                    color: checkTag(tagItem)
                                      ? '#fff'
                                      : tagItem.color,
                                  }"
                                ></i>
                              </div>
                            </el-col>
                            <el-col :span="3" class="pd-5 text-center">
                              <i
                                class="el-icon-edit"
                                @click="editTag(tagItem, true)"
                              ></i>
                            </el-col>
                          </el-row>
                        </div>
                      </div>
                      <el-button
                        @click="
                          editTag('', false), (tagData.color = colorTag[0])
                        "
                        v-if="allTag.length < 15"
                        class="button-new-tag mg-t-2 w-100"
                        size="small"
                      >
                        <span class="font-14"> สร้างแท็กใหม่ </span>
                      </el-button>
                    </div>
                    <div v-show="tagIndex == 2">
                      <el-form
                        :label-position="'top'"
                        label-width="100px"
                        @submit.prevent.native
                        @keyup.enter.native="
                          tagData.edit ? updateTag() : createTag()
                        "
                      >
                        <el-form-item label="ชื่อ">
                          <el-input v-model="tagData.name"></el-input>
                        </el-form-item>
                        <el-form-item label="เลือกสี">
                          <div
                            v-for="(color, index) in colorTag"
                            :key="index"
                            @click="[(tagData.color = color)]"
                            class="colorPicker float-left cr-pointer"
                            :style="{ backgroundColor: color }"
                          >
                            <i
                              v-if="tagData.color == color"
                              class="el-icon-check checkPicker"
                            ></i>
                          </div>
                          <el-color-picker
                            @input="$emit('updateTask')"
                            v-model="colorCustom"
                            @change="colorCustomAdd()"
                            class="color-picker-all"
                          ></el-color-picker>
                        </el-form-item>
                        <div class="is-flex js-between">
                          <el-button
                            @click="(tagIndex = 1), $emit('emitLeft')"
                            class="button-new-tag"
                            size="small"
                          >
                            <span class="font-14"> ย้อนกลับ </span>
                          </el-button>
                          <el-button
                            type="primary"
                            @click="tagData.edit ? updateTag() : createTag()"
                            class="button-new-tag"
                            size="small"
                          >
                            <span class="font-14">
                              {{ tagData.edit ? "บันทึก" : "สร้าง" }}
                            </span>
                          </el-button>
                        </div>
                      </el-form>
                    </div>
                  </div>
                  <div slot="reference" @click="tagIndex = 1">
                    <el-button
                      class="button-new-tag float-left mg-r-3"
                      size="small"
                      :disabled="isModal == 'view' ? true : false"
                      :class="isModal == 'view' ? 'custom-autocomplete' : ''"
                    >
                      <i class="el-icon-plus"></i>
                    </el-button>
                  </div>
                </el-popover>
                <div
                  v-for="item in formCalendar.tag"
                  :key="item._id"
                  :title="item.name"
                  class="tags-show float-left pd-5 pd-x-4 mg-r-2 mg-b-2 text-white plain text-over break-all"
                  :style="{ backgroundColor: item.color }"
                >
                  {{ item.name }}
                </div>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="js-end is-flex mg-t-4" v-if="isModal != 'view'">
          <el-button round @click="closeForm()">
            <span class="font-14"> Cancel </span>
          </el-button>
          <el-button
            type="warning"
            round
            :loading="loadingBtn"
            native-type="submit"
          >
            <span class="font-14"> Save </span>
          </el-button>
        </div>
      </el-form>
    </el-dialog>

    <el-dialog
      :visible.sync="dialogCalendarView"
      :before-close="closeForm"
      :show-close="deleteCalendarStatus == true ? false : true"
    >
      <template slot="title">
        <p class="font-28 mg-b-less color-main-1">
          <span>Calendar </span>
        </p>
      </template>
      <el-form :model="formCalendar" ref="ruleForm">
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item prop="calendarName" label="Title">
              <el-input
                placeholder="Title name"
                v-model="formCalendar.calendarName"
                disabled
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24" :md="12">
            <el-form-item prop="date" label="Date (วันที่)">
              <el-date-picker
                type="date"
                placeholder="Pick a date"
                v-model="formCalendar.date"
                style="width: 100%"
                disabled
              ></el-date-picker>
            </el-form-item>
          </el-col>

          <el-col :span="24" :md="12">
            <el-form-item prop="timeStart" label="Time (เวลา)" class="time-box">
              <el-row :gutter="10">
                <el-col :span="24" :sm="12">
                  <el-time-select
                    disabled
                    placeholder="Start time"
                    v-model="formCalendar.timeStart"
                    :picker-options="{
                      start: '07:00',
                      step: '00:30',
                      end: '19:00',
                    }"
                  >
                  </el-time-select>
                </el-col>
                <el-col :span="24" :sm="12">
                  <el-form-item prop="timeEnd" class="mg-b-less">
                    <el-time-select
                      disabled
                      placeholder="End time"
                      v-model="formCalendar.timeEnd"
                      :picker-options="{
                        start: '07:00',
                        step: '00:30',
                        end: '19:00',
                        minTime: formCalendar.timeStart,
                      }"
                    >
                    </el-time-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="Auditor name (ชื่อผู้ตรวจ)" prop="auditorId">
              <el-select
                disabled
                clearable
                v-model="formCalendar.auditorId"
                placeholder="Select Audit cycle"
              >
                <el-option
                  v-for="(item, index) in optionsAuditor"
                  :key="index"
                  :label="item.name"
                  :value="item._id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24" class="set-tag">
            <el-form-item label="Tag (แท็ก)" prop="tag">
              <div class="mg-t-1">
                <el-button
                  class="button-new-tag float-left mg-r-3"
                  size="small"
                  disabled
                >
                  <i class="el-icon-plus"></i>
                </el-button>
                <div
                  v-for="item in formCalendar.tag"
                  :key="item._id"
                  :title="item.name"
                  class="float-left pd-5 pd-x-4 mg-r-2 mg-b-2 text-white plain text-over break-all tags-show"
                  :style="{ backgroundColor: item.color }"
                >
                  {{ item.name }}
                </div>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>

    <el-dialog
      width="45%"
      :visible.sync="dialogDeleteCalendar"
      :show-close="false"
      top="22vh"
    >
      <p class="font-28 mg-b-less color-main-1 text-center">
        <span>ยืนยันการลบ</span> <br />
        <span class="font-20">กรุณาตรวจสอบก่อนลบข้อมูล </span>
      </p>
      <div class="js-center is-flex mg-t-4">
        <el-button round @click="dialogDeleteCalendar = false">
          <span class="font-14"> Cancel </span>
        </el-button>
        <el-button
          type="warning"
          round
          :loading="loadingBtn"
          native-type="submit"
          @click="deleteCalendar(formCalendar._id)"
        >
          <span class="font-14"> Save </span>
        </el-button>
      </div>
    </el-dialog>
  </DefaultTemplate>
</template>

<script>
import "@/mixin/DefaultTemplate";
import { HTTP } from "@/service/axios";
import { mapState } from "vuex";
// import Scheduler from "@/components/calendar/Scheduler";
import CalendarCustom from "@/components/calendar/CalendarCustom";

import FillterCalendar from "@/components/calendar/FillterCalendar";
import moment from "moment";

export default {
  components: {
    // Scheduler,
    CalendarCustom,
    FillterCalendar,
  },
  computed: {
    ...mapState({
      _token: (state) => state._token,
    }),
  },
  async mounted() {
    await this.setDateOfWeek("start");
    await this.searchAll();
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          const today = new Date();
          return time.getTime() < today.setDate(today.getDate() - 1);
        },
      },
      loading: true,
      loadingTable: true,
      filter: {
        tagId: [],
        auditorId: [],
        search: "",
        firstDate: "",
        lastDate: "",
      },
      openTab: true,
      dialogCalendar: false,
      dialogCalendarView: false,
      loadingBtn: false,
      colorCustom: "",
      colorTag: [
        "#61BD4F",
        "#f2d600",
        "#FF9F1A",
        "#EB5A46",
        "#C377E0",
        "#0079BF",
        "#00C2E0",
        "#51E898",
        "#FF78CB",
        "#344563",
      ],
      dataCalendar: [],
      tagIndex: 1,
      allTag: [],
      optionsAuditor: [],
      formCalendar: {
        calendarName: "",
        date: "",
        timeStart: "",
        timeEnd: "",
        auditorId: "",
        tag: [],
        status: 1,
      },
      rules: {
        calendarName: [
          {
            required: true,
            message: "กรุณากรอก Title",
            trigger: "blur",
          },
        ],
        date: [
          {
            required: true,
            message: "กรุณาเลือกวันที่",
            trigger: "change",
          },
        ],
        timeStart: [
          {
            required: true,
            message: "กรุณาเลือกเวลา",
            trigger: "change",
          },
        ],
        timeEnd: [
          {
            required: true,
            message: "กรุณาเลือกเวลา",
            trigger: "change",
          },
        ],
        auditorId: [
          {
            required: true,
            message: "กรุณาเลือกชื่อผู้ตรวจ",
            trigger: "change",
          },
        ],
        tag: [
          {
            required: true,
            message: "กรุณาเลือก Tag",
            trigger: "change",
          },
        ],
      },
      tagData: {
        edit: false,
        name: "",
        color: "",
      },
      isModal: "add",
      deleteCalendarStatus: false,
      dialogDeleteCalendar: false,
      setTag: [],
      dayOfWeek: [],
    };
  },
  methods: {
    setDateOfWeek(type, dayFirst) {
      this.dayOfWeek = [];
      let today = new Date(); // get today's date
      if (type == "filter") {
        today = dayFirst;
      }
      for (let index = 0; index < 7; index++) {
        let setDay = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() + (index - today.getDay())
        );

        this.dayOfWeek.push(setDay);
      }
      if (type == "start") {
        this.filter.lastDate = this.dayOfWeek[6];
        this.filter.firstDate = this.dayOfWeek[0];
      }
    },
    getAuditor() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      delete HTTP.defaults.headers.common["X-CSRF-TOKEN"];

      HTTP.get(`auditor/user`)
        .then((res) => {
          if (res.data.success) {
            this.optionsAuditor = res.data.obj;
            if (this.user.role == 50) {
              this.formCalendar.auditorId = this.optionsAuditor[0]._id;
            }
          }
        })
        .catch((e) => {
          if (typeof e.response != "undefined") {
            if (e.response.data.status == 401) {
              this.checkAuth();
            }
          }
        });
    },
    async getCalendar() {
      HTTP.post(`calendar/all`, this.filter)
        .then((res) => {
          if (res.data.success) {
            this.dataCalendar = res.data.obj;
          }
        })
        .catch((e) => {
          if (typeof e.response != "undefined") {
            if (e.response.data.status == 401) {
              this.checkAuth();
            }
          }
        })
        .finally(() => {
          this.loading = false;
          this.loadingTable = false;
        });
    },
    submitCalendar() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.loadingTable = true;
          this.loadingBtn = true;
          if (this.isModal == "edit") {
            this.editCalendar();
          } else {
            this.addCalendar();
          }
        }
      });
    },
    async addCalendar() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;

      let momentDate = moment(this.formCalendar.date).format("YYYY-MM-D");
      let setDate = momentDate;
      let obj = {
        calendarName: this.formCalendar.calendarName,
        date: moment(setDate),
        timeStart: this.formCalendar.timeStart,
        timeEnd: this.formCalendar.timeEnd,
        status: "1",
        auditorId: this.formCalendar.auditorId,
        tagId: this.formCalendar.tag[0]._id,
      };
      HTTP.post(`calendar/create`, obj)
        .then(async (res) => {
          if (res.data.success) {
            await this.searchAll();
            this.$notify({
              title: "สร้าง Calendar สำเร็จ!",
              type: "success",
              customClass: "success",
            });
            this.closeForm();
          } else {
            this.loadingTable = false;
            this.loadingBtn = false;

            this.$notify({
              title: "ผู้ตรวจ ไม่ว่างในช่วงวันและเวลาดังกล่าว!",
              type: "error",
              customClass: "error",
            });
          }
        })
        .catch((e) => {
          console.log("fetchTest", e);
        })
        .finally(() => {});
    },
    async editCalendar() {
      let momentDate = moment(this.formCalendar.date).format("YYYY-MM-D");
      let setDate = momentDate;
      let obj = {
        calendarName: this.formCalendar.calendarName,
        date: moment(setDate),
        timeStart: this.formCalendar.timeStart,
        timeEnd: this.formCalendar.timeEnd,
        status: this.formCalendar.status,
        auditorId: this.formCalendar.auditorId,
        tagId: this.formCalendar.tag[0]._id,
      };

      this.loadingTable = true;
      this.loadingBtn = true;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      HTTP.put("calendar/edit/" + this.formCalendar._id, obj)
        .then(async (res) => {
          if (res.data.success) {
            await this.searchAll();

            this.$notify({
              title: "แก้ไข Calendar สำเร็จ!",
              type: "success",
              customClass: "success",
            });
            this.closeForm();
          } else {
            this.loadingTable = false;
            this.loadingBtn = false;

            this.$notify({
              title: "ผู้ตรวจ ไม่ว่างในช่วงวันและเวลาดังกล่าว!",
              type: "error",
              customClass: "error",
            });
          }
        })
        .catch((e) => {
          if (typeof e.response != "undefined") {
            if (e.response.data.status == 401) {
              this.checkAuth();
            }
          }
        });
    },
    getTag() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      delete HTTP.defaults.headers.common["X-CSRF-TOKEN"];

      HTTP.get(`tags/all`)
        .then((res) => {
          if (res.data.success) {
            this.allTag = res.data.obj;
            this.setTag = this.allTag.filter((row) => !!row.name);
          }
        })
        .catch((e) => {
          console.log("fetchTest", e);
        });
    },
    toggleTag(data) {
      let findIndex = this.formCalendar.tag.findIndex(
        (tag) => tag._id == data._id
      );
      if (findIndex < 0) {
        this.formCalendar.tag.push(data);
      } else {
        this.formCalendar.tag.splice(findIndex, 1);
      }
    },
    checkTag(data) {
      if (this.formCalendar.tag.length > 0) {
        let color = this.formCalendar.tag.filter(
          (search) => search._id === data._id
        );
        if (color.length > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    editTag(item, edit = false) {
      this.tagIndex = 2;
      if (edit) {
        this.tagData._id = item._id;
        this.tagData.edit = edit;
        this.tagData.name = item.name;
        this.tagData.color = item.color;
      } else {
        this.tagData._id = "";
        this.tagData.edit = false;
        this.tagData.name = "";
        this.tagData.color = "";
      }
    },
    closeForm() {
      this.$refs["ruleForm"].resetFields();
      this.dialogCalendar = false;
      this.deleteCalendarStatus = false;
      this.dialogCalendarView = false;
      this.dialogDeleteCalendar = false;
      this.loadingTable = false;
      this.loading = false;
      this.loadingBtn = false;
      this.isModal = "add";
      this.formCalendar = {
        calendarName: "",
        date: "",
        timeStart: "",
        timeEnd: "",
        auditorId: "",
        tag: [],
        status: 1,
      };
    },
    createTag() {
      let obj = {
        color: this.tagData.color,
        name: this.tagData.name,
      };
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      HTTP.post("tags/create", obj)
        .then((res) => {
          if (res.data.success) {
            this.getTag();
            this.tagData.name = "";
            this.tagData.color = "";
            this.tagData.edit = false;
            this.tagIndex = 1;
          }
        })
        .catch((e) => {
          if (typeof e.response != "undefined") {
            if (e.response.data.status == 401) {
              this.checkAuth();
            }
          }
        });
    },
    updateTag() {
      let obj = {
        name: this.tagData.name,
        color: this.tagData.color,
      };
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      HTTP.put("tags/edit/" + this.tagData._id, obj)
        .then((res) => {
          if (res.data.success) {
            this.getTag();
            this.tagData.name = "";
            this.tagData.color = "";
            this.tagData.edit = false;
            this.tagIndex = 1;
          }
        })
        .catch((e) => {
          if (typeof e.response != "undefined") {
            if (e.response.data.status == 401) {
              this.checkAuth();
            }
          }
        });
    },
    colorCustomAdd() {
      this.colorTag.push(this.colorCustom);
      this.tagData.color = this.colorCustom;
    },
    getEditCalendar(id, type) {
      if (type == "view") {
        this.isModal = "view";
      } else {
        this.isModal = "edit";
      }
      this.dialogCalendar = true;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      delete HTTP.defaults.headers.common["X-CSRF-TOKEN"];

      HTTP.get("calendar/" + id)
        .then((res) => {
          if (res.data.success) {
            let findTag = this.allTag.find(
              (tags) => tags._id == res.data.obj.tagId
            );
            if (findTag != undefined && findTag != null) {
              findTag = [{ ...findTag }];
            } else {
              findTag = [];
            }

            this.formCalendar = { ...res.data.obj, tag: findTag };
          }
        })
        .catch((e) => {
          if (typeof e.response != "undefined") {
            if (e.response.data.status == 401) {
              this.checkAuth();
            }
          }
        });
    },
    openCalendar(id, type) {
      if (type == "auto") {
        this.$router.push(`/calendar/auto/${id}`);
      } else {
        HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
        delete HTTP.defaults.headers.common["X-CSRF-TOKEN"];

        HTTP.get("calendar/" + id)
          .then((res) => {
            if (res.data.success) {
              if (type == "delete") {
                this.deleteCalendarStatus = true;
                this.dialogDeleteCalendar = true;
              } else {
                this.dialogCalendarView = true;
              }

              let findTag = this.allTag.find(
                (tags) => tags._id == res.data.obj.tagId
              );
              this.formCalendar = { ...res.data.obj, tag: [{ ...findTag }] };
            }
          })
          .catch((e) => {
            if (typeof e.response != "undefined") {
              if (e.response.data.status == 401) {
                this.checkAuth();
              }
            }
          });
      }
    },
    async setWeek(type) {
      if (type == "next") {
        this.filter.firstDate.setDate(this.filter.firstDate.getDate() + 7);
        this.filter.lastDate.setDate(this.filter.lastDate.getDate() + 6);
      }
      if (type == "prev") {
        this.filter.firstDate.setDate(this.filter.firstDate.getDate() - 7);
        this.filter.lastDate.setDate(this.filter.lastDate.getDate() - 6);
      }
      this.loadingTable = true;

      if (type == "today") {
        this.setDateOfWeek("start");
      }

      this.setDateOfWeek("filter", this.filter.firstDate);
      await this.searchAll();
    },
    async searchAll(type) {
      this.loadingTable = true;
      await this.getAuditor();
      await this.getTag();
      if (type == "input") {
        let debounce = null;
        clearTimeout(debounce);
        debounce = setTimeout(() => {
          this.getCalendar();
        }, 600);
      } else {
        await this.getCalendar();
      }
    },
    deleteCalendar(id) {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      HTTP.delete(`calendar/delete/` + id)
        .then((res) => {
          if (res.data.success) {
            this.$notify({
              title: "ลบ Calendar สำเร็จ!",
              type: "success",
              customClass: "success",
            });
            this.searchAll();
            this.closeForm();
          }
        })
        .catch((e) => {
          console.log("fetchTest", e);
        });
    },
    changeTime() {
      if (this.formCalendar.timeStart > this.formCalendar.timeEnd) {
        this.formCalendar.timeEnd = "";
      }
    },
    openAddCalendar() {
      this.dialogCalendar = true;
      if (this.user.role == 50) {
        this.formCalendar.auditorId = this.optionsAuditor[0]._id;
      }
    },
  },
};
</script>
