<template>
  <div>
    <el-collapse v-model="activeNames">
      <el-collapse-item title="Tag (แท็ก)" name="1">
        <el-checkbox-group v-model="filter.tagId" id="checkbox-group">
          <el-checkbox
            :label="item._id"
            v-for="(item, index) in tag"
            :key="index"
            @change="$emit('searchCalendar')"
            >{{ item.name }}</el-checkbox
          >
        </el-checkbox-group>
      </el-collapse-item>
      <el-collapse-item title="Auditor (ผู้ตรวจ)" name="2">
        <el-checkbox-group v-model="filter.auditorId">
          <el-checkbox
            :label="item._id"
            v-for="(item, index) in auditor"
            :key="index"
            @change="$emit('searchCalendar')"
            >{{ item.name }}</el-checkbox
          >
        </el-checkbox-group>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>
<script>
export default {
  props: {
    tag: {
      type: Array,
      default: [],
    },
    auditor: {
      type: Array,
      default: [],
    },
    filter: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      activeNames: ["1", "2"],
    };
  },

  mounted() {
    this.$nextTick(() => {
      this.colorCheckbox();
    });
  },
  watch: {
    tag() {
      this.colorCheckbox();
    },
  },
  methods: {
    colorCheckbox() {
      let group = document.getElementById("checkbox-group");
      setTimeout(() => {
        let checkbox = group.getElementsByClassName("el-checkbox");
        for (let index = 0; index < checkbox.length; index++) {
          let inbox =
            checkbox[index].getElementsByClassName("el-checkbox__inner");
          inbox[0].style.backgroundColor = this.tag[index].color;
        }
      }, 100);
    },
  },
};
</script>
