<template>
  <div>
    <p class="calendar-name">{{ calendar.calendarName }}</p>
    <div class="box-time">
      <span class="show-time">
        {{ calendar.timeStart + "-" + calendar.timeEnd }}
      </span>
    </div>
    <el-popover
      placement="right-start"
      trigger="click"
      class="popover-tags"
      :popper-class="`in-tag`"
    >
      <p
        @click="
          $emit(
            'openCalendar',
            calendar._id,
            calendar.status == 2 ? 'auto' : 'view'
          )
        "
      >
        ดูข้อมูล
      </p>
      <p
        @click="$emit('editCalendar', calendar._id, 'edit')"
        v-if="user.role > 50"
      >
        แก้ไข
      </p>
      <p
        v-if="user.role > 50 && calendar.status != 2"
        @click="
          $emit(
            'openCalendar',
            calendar._id,
            calendar.status == 2 ? 'auto' : 'delete'
          )
        "
      >
        ลบข้อมูล
      </p>
      <i class="fas fa-ellipsis-v" slot="reference"></i>
    </el-popover>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  props: ["calendar"],
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
};
</script>
