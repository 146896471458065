var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',{staticClass:"calendar-name"},[_vm._v(_vm._s(_vm.calendar.calendarName))]),_c('div',{staticClass:"box-time"},[_c('span',{staticClass:"show-time"},[_vm._v(" "+_vm._s(_vm.calendar.timeStart + "-" + _vm.calendar.timeEnd)+" ")])]),_c('el-popover',{staticClass:"popover-tags",attrs:{"placement":"right-start","trigger":"click","popper-class":`in-tag`}},[_c('p',{on:{"click":function($event){return _vm.$emit(
          'openCalendar',
          _vm.calendar._id,
          _vm.calendar.status == 2 ? 'auto' : 'view'
        )}}},[_vm._v(" ดูข้อมูล ")]),(_vm.user.role > 50)?_c('p',{on:{"click":function($event){return _vm.$emit('editCalendar', _vm.calendar._id, 'edit')}}},[_vm._v(" แก้ไข ")]):_vm._e(),(_vm.user.role > 50 && _vm.calendar.status != 2)?_c('p',{on:{"click":function($event){return _vm.$emit(
          'openCalendar',
          _vm.calendar._id,
          _vm.calendar.status == 2 ? 'auto' : 'delete'
        )}}},[_vm._v(" ลบข้อมูล ")]):_vm._e(),_c('i',{staticClass:"fas fa-ellipsis-v",attrs:{"slot":"reference"},slot:"reference"})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }